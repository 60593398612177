var initDefaultFileUploader = function(input, loadInitialPreview = true) {
  var token = $('meta[name="csrf-token"]').attr('content');
  var urls = input.parents('div.attach_container').find('input.avatar-url');
  var url_values = [];
  var download_urls = [];
  var confirmed_tags = [];
  var initialPreviewShowDelete = false;
  var pdfExtRegex = /.pdf$/;
  var msDocExtRegex = /.doc$/;
  var msDocxExtRegex = /.docx$/;
  var dropTitle = 'Drag & drop files here …';
  var dropHint = '<br>(or click to select {files})';
  var layoutTemplates = {};

  if (input.attr('readonly')) {
    dropTitle = '';
    dropHint = '';
  }

  if(input.attr('data-initial-preview-show-delete') == 'true') {
    initialPreviewShowDelete = true;
  }

  if (loadInitialPreview) {
    urls.each(function() {
      let downloadUrl = $(this).val();
      let answerId = $(this).data('answerId');
      let fileType = 'image';
      let caption = '';

      if (downloadUrl.match(pdfExtRegex)) {
        fileType = 'pdf';
      }

      if (downloadUrl.match(msDocExtRegex)) {
        fileType = 'office';
      }

      if (downloadUrl.match(msDocxExtRegex)) {
        fileType = 'office';
      }

      let fileNameOffset = downloadUrl.lastIndexOf('/');
      if (fileNameOffset) {
        caption = decodeURI(
          downloadUrl.substring(fileNameOffset + 1)
        );
      }

      url_values.push(downloadUrl);
      download_urls.push({
        caption: caption,
        downloadUrl: downloadUrl,
        key: answerId,
        type: fileType,
        width: "120px",
        showRemove: EMS.userIsAdmin || !$(this).data('answerConfirmed'),
        confirmed: $(this).data('answerConfirmed')
      });
      confirmed_tags.push({
        '{CONFIRMED}': $(this).data('answerConfirmed') ? 'Confirmed' : 'Not Confirmed'
      });
    });
  }

  var extraData = {
    'authenticity_token': token,
    'question_id': input.attr('question-id'),
    'q_type': input.attr('q-type'),
    'user_id': input.attr('user-id'),
    'block_index': input.attr('block_index')
  }

  if (input.data('confirmable')) {
    layoutTemplates.footer = '<div class="file-thumbnail-footer">\n' +
    '    <div class="file-footer-caption">\n' +
    '       <div class="file-caption-info">\n' +
    '          {caption}\n' +
    '      </div>\n' +
    '      <div class="file-caption-info">{CONFIRMED}</div>\n' +
    '    </div>\n' +
    '    {actions}\n' +
    '</div>';
  }

  console.warn('Attachent.js: fileinput _meta_section load 1');
  input.fileinput({
    allowedFileExtensions: ['jpeg','jpg', 'png', 'gif', 'pdf', 'docx', 'txt', 'doc'],
    uploadUrl: "/answers",
    uploadAsync: true,
    deleteUrl: "/answers/destroy",
    showUpload: false, // hide upload button
    showCancel: false,
    showCaption: true,
    overwriteInitial: false, // append files to initial preview
    minFileCount: 1,
    maxFileCount: 5,
    showBrowse: false,
    showRemove: true,
    initialPreviewShowDelete: initialPreviewShowDelete,
    browseOnZoneClick: true,
    dropZoneTitle: dropTitle,
    dropZoneClickTitle: dropHint,
    initialPreviewAsData: true,
    initialPreviewConfig: download_urls,
    initialPreview: url_values,
    deleteExtraData: extraData,
    uploadExtraData: extraData,
    previewThumbTags: {
      '{CONFIRMED}': ''
    },
    initialPreviewThumbTags: confirmed_tags,
    layoutTemplates: layoutTemplates,
  }).on("filebatchselected", function(event, files) {
    input.fileinput("upload");
  }).on('filebeforedelete', function() {
    var aborted = !window.confirm('Are you sure you want to delete this file?');
    return aborted;
  }).on('fileuploaded', function(event, data, previewId, index, fileId) {
    const res = data.jqXHR.responseJSON;
    if (res.confirmation.pending_validation) {
      $(`#section-pending-label-${res.confirmation.section_id}`).text('pending validation').show();
    }
  });
};

$('document').ready(function() {
  $('div.attach_container input.input-1').each(function() {
    initDefaultFileUploader($(this));
  })

  $('[id=submitDenyAttachment]').click(function() {
    let target = $(this);
    let userId = target.data('user-id');
    let questionId = target.data('question-id');
    let blockIndex = target.data('block-index');

    let data = {
      block_index: blockIndex,
      question_id: questionId
    }

    $.ajax({
      url: '/answers/deny-attachments/' + userId,
      type: 'POST',
      contentType : 'application/json',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      dataType: 'json',
      data: JSON.stringify(data)

    }).done(function() {
      location.reload();

    }).fail(function() {
      console.error('Can not delete attachments.')
    });
  });
});

if (!($.initDefaultFileUploader)) {
  $.initDefaultFileUploader = initDefaultFileUploader;
}
